import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Fitness and Spirit- My Story" />
    <div className="container-main">
      <div className="container-main_div">
        <h1>Privacy Policy</h1>
        <p>
          <span className="font-bold">Fitness and Spirit </span>
          respects your privacy and is committed to protecting it through
          compliance with this policy. I believe you should fully understand the
          terms and conditions surrounding the capture and use of that
          information. This privacy statement discloses what information we
          gather and how we use it.
        </p>
        <h2>Collecting and Using Your Personal Data</h2>
        <p>
          I use Your Personal data to provide and improve all services I
          provide. By using my service, You agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>
        <h2>Personal Data</h2>
        <p>
          While using my Service, I may ask You to provide me with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
        </ul>
        <h2>Health History and Programming</h2>
        <p>
          Most of us feel that our health information is private and should be
          protected. That is why there is a federal law that sets rules for
          health care providers and health insurance companies about who can
          look at and receive our health information. This law, called the
          Health Insurance Portability and Accountability Act of 1996 (HIPAA),
          gives you rights over your health information, including the right to
          get a copy of your information, make sure it is correct, and know who
          has seen it. Through collaboration and/or communication with your
          Healthcare Provider, your Health information may include the
          following:
        </p>
        <ul>
          <li>Medical-Health History</li>
          <li>Medical Exercise Functional Outcome Measures</li>
          <li>Letter of Intent to Collaborate with your Medical Provider</li>
          <li>
            Diagnosis, Prognosis, and/or Treatment given by your Medical
            Professional
          </li>
          <li>Discharge Papers from your Medical Provider</li>
          <li>
            Documentation of client progress and exercise recommendations with
            Healthcare Providers
          </li>
          <li>Exercise Programming</li>
          <li>Documentation of client Functional Outcomes</li>
        </ul>
        <h2>Links</h2>
        <p>
          This web site may contain links to other sites. Please be aware that
          Fitness and Spirit is not responsible for the privacy practices of
          such other sites. I encourage my users to be aware when they leave my
          site and to read the privacy statements of each and every web site
          that collects personally identifiable information. This privacy
          statement applies solely to information collected by this web site.
        </p>
        <h2>Contact</h2>
        <p>
          If you feel that I am not abiding by my posted privacy policy, you
          should contact me via email at{' '}
          <a href="mailto: fitnessnspirit@gmail.com" className="text-blue-700">
            fitnessnspirit@gmail.com
          </a>
          .
        </p>
        <p>
          The information provided on this website is provided “as is” without
          any representations or warranties, express or implied, including, but
          not limited to, the implied warranties of merchantability, fitness for
          a particular purpose, or non-infringement. Fitness and Spirit, makes
          no representations or warranties in relation to this website or the
          information and materials provided on this website.
        </p>
        <p>
          Nothing on this website constitutes, or is meant to constitute, advice
          of any kind. If you require advice in relation to any medical matter
          you should consult an appropriate medical professional.
          <span className="italic">
            Always consult a medical professional before beginning an exercise
            program.
          </span>
        </p>
        <p>
          Any links to other websites are solely as a resource convenience and
          the presence of such links does not imply a responsibility for the
          linked site or an endorsement of the linked site, its operator, or its
          contents. Fitness and Spirit, will not be liable to you in contract or
          torts or otherwise in relation to the contents of, or use of,
          information contained in this website. By using this website, you
          agree that the exclusions and limitations of liability set out in this
          website disclaimer are reasonable. If you do not think they are
          reasonable you must not use this website or any of the information
          contained herein.
        </p>
        <h2>Changes</h2>
        <p>
          If you would like to review and request any changes to your personal
          information on file with me, you may direct the request to me in
          writing at{' '}
          <a href="mailto: fitnessnspirit@gmail.com" className="text-blue-700">
            fitnessnspirit@gmail.com
          </a>
          .I may not accommodate such a request if I believe compliance with the
          request would violate any law, regulation, contract, or other legal
          obligation or cause the information to be incorrect. I reserve the
          right to deny, in my sole discretion and for any reason, any request
          to review, change, How I Use Your Information.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPolicy
